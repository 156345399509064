<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina 
                Titlu="Clienti"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
              />
            </div>
          </template>
          <div class="filtre">
            <el-form @submit.prevent.native="refresh_info()">
              <el-row :gutter="10">
                <el-col>
                  <el-form-item label="Nume">
                    <el-input v-model="Filters.Nume" />
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Categorie">
                    <el-select
                      class="full-width"
                      v-model="Filters.IdCategorieClient"
                      filterable
                    >
                      <el-option label="Toate" value="-1"></el-option>
                      <el-option
                        v-for="item in Info.categorii"
                        :key="'categorii' + item.Id"
                        :label="item.Denumire"
                        :value="item.Id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Tip client">
                    <el-select v-model="Filters.PF" class="full-width">
                      <el-option label="Toate" value="-1"></el-option>
                      <el-option label="Persoana fizica" value="1"></el-option>
                      <el-option
                        label="Persoana juridica"
                        value="0"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Status">
                    <el-select v-model="Filters.Dezactivat" class="full-width">
                      <el-option label="Toti" value="-1"></el-option>
                      <el-option label="Dezactivati" value="1"></el-option>
                      <el-option label="Activi" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col style="width: 100%">
                <div class="footer-form">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="refresh_info()"
                  >
                    Aplica
                  </el-button>
                  <el-button
                    type="danger"
                    native-type="submit"
                    @click="reset()"
                  >
                    Reseteaza
                  </el-button>
                </div>
              </el-col>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
      <el-table-column prop="Id" label="Id"></el-table-column>
      <el-table-column prop="Nume" label="Nume"></el-table-column>
      <el-table-column prop="NumeCategorie" label="Categorie"></el-table-column>
      <el-table-column prop="TermenPlata" label="Termen plata" />
      <el-table-column prop="Discount" label="Discount" />
      <el-table-column prop="PF" label="Persoana fizica">
        <template slot-scope="scope">
          {{ scope.row.PF == 0 ? "Nu" : "Da" }}
        </template>
      </el-table-column>      
      <el-table-column label="Special">
        <template slot-scope="scope">
          {{ scope.row.Special == 0 ? "Nu" : "Da" }}
        </template>
      </el-table-column>
      <el-table-column prop="Dezactivat" label="Dezactivat">
        <template slot-scope="scope">
          {{ scope.row.Dezactivat == 0 ? "Nu" : "Da" }}
        </template>
      </el-table-column>
      <el-table-column
        style="text-align: center;"
        fixed="right"
        label="Actiuni"
        width="100px"
      >
        <template slot-scope="scope">
          <el-tooltip content="Modificare">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              class="butonReg"
              circle
              @click="show_dialog(scope.row.Id)"
            />
          </el-tooltip>

          <!-- <el-tooltip content="Dezactiveaza">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              :disabled="Boolean(Number(scope.row.Dezactivat))"
              @click="delete_item(scope.row)"
            />
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <Clienti-dialog ref="dlg" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "clienti",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Clienti-dialog": Clienti_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {
        Categorii: [],
      },
      Filters: {
        Id: "",
        Nume: "",
        PF: "-1",
        IdCategorieClient: "-1",
        Dezactivat: "0",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      var response = await this.post("clienti/get_info");
      this.Info.categorii = response.categorii;
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("clienti/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("clienti");
    },
    reset() {
      this.Filters = {
        Id: "",
        Nume: "",
        PF: "-1",
        IdCategorieClient: "-1",
        Dezactivat: "0",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      var confirm = await this.$confirm(`Sunteti sigur ?`, "Warning");
      if (confirm) {
        await this.post("clienti/delete_item", { id: item.Id });
        this.refresh_info();
      }
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  // width: 100% !important;
  // display: grid;
  grid-template-columns: repeat(2);
  .el-col {
    width: 20%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
